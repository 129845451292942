/* eslint-disable react/jsx-pascal-case */
import React, { lazy, Suspense, useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
// bootstarp
// import 'bootstrap/dist/css/bootstrap.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
// CSS
import "react-toastify/dist/ReactToastify.css";
import "./Assets/Css/App.css";
import "./Assets/Css/Responsive.css";

import ScrollToTop from "react-scroll-to-top";
import { ToastContainer } from "react-toastify";
import NotFount_404 from "./Components/404";
import PageLoader from "./Components/pageLoader";
import OneSignal from "react-onesignal";

const Header = lazy(() => import("./Layout/Header"));
const Footer = lazy(() => import("./Layout/Footer"));
const Home = lazy(() => import("./Components/Home"));
const LuckyNumberBirth = lazy(() => import("./Components/LuckyNumberBirth"));
const LuckyNumberDream = lazy(() => import("./Components/LuckyNumberDream"));
const About = lazy(() => import("./Components/About"));

const App = () => {
  const lang = localStorage.getItem("i18nextLng");

  useEffect(() => {
    document.title = "SpinMahika";
  }, [lang]);

  useEffect(() => {
    OneSignal.init({
      appId: process.env.REACT_APP_ONESIGNAL_APPKEY,
      autoRegister: true,
      autoResubscribe: true,
      serviceWorkerPath:'/oneSignalSDKWorker.js',
    })
    .then(res => {})
    .catch(err => {});
  }, []);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPCHA_SITE_KEY}
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: "head", // optional, default to "head", can be "head" or "body",
        nonce: undefined, // optional, default undefined
      }}
      container={{
        parameters: {
          badge: "bottomright",
          theme: "dark",
        },
      }}
    >
      <Router>
        <Header />
        <Suspense fallback={<PageLoader />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/birthdate/horoscope" element={<LuckyNumberBirth />} />
            <Route path="/birthdate/chinese" element={<LuckyNumberBirth />} />
            <Route
              path="/birthdate/numerology"
              element={<LuckyNumberBirth />}
            />
            <Route path="/dream" element={<LuckyNumberDream />} />
            <Route path="/about" element={<About />} />
            <Route path="*" element={<NotFount_404 />} />
          </Routes>
          <ScrollToTop
            smooth
            viewBox="0 0 320 512"
            svgPath="M177 255.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 351.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 425.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1zm-34-192L7 199.7c-9.4 9.4-9.4 24.6 0 33.9l22.6 22.6c9.4 9.4 24.6 9.4 33.9 0l96.4-96.4 96.4 96.4c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9l-136-136c-9.2-9.4-24.4-9.4-33.8 0z"
          />
        </Suspense>
        <Footer />
        <ToastContainer />
      </Router>
    </GoogleReCaptchaProvider>
  );
};

export default App;
