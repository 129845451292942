import React from 'react'
import Meta from "react-document-meta";

const NotFount_404 = () => {
    const meta = {
      title: 'Some Meta Title',
      description: 'I am a description, and I can create multiple tags',
      canonical: 'http://example.com/path/to/page',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'react,meta,document,html,tags'
        }
      }
    };
    return (
      <>
        <Meta {...meta}>
          <div className='' style={{ minHeight:331, minWidth:'100%' }}>
            <h4 style={{ marginTop:145, marginLeft:10, }} className='text-light'>Page not found</h4>
          </div>
        </Meta>
      </>
    )
};

export default NotFount_404;
