/* eslint-disable jsx-a11y/alt-text */
import React from 'react'

const PageLoader = () => {
    return (
      <div className='loder-main'>
            <img src={require('../Assets/images/spinmahika.gif')} style={{ height:120, width:120,}}/>
      </div>
    )
};

export default PageLoader
